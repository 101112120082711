  // core version + navigation, pagination modules:
  import Swiper, { Navigation, Pagination, Autoplay } from 'swiper';
  // import Swiper and modules styles

  // init Swiper:
  const swiper = new Swiper('.swiper', {
    // configure Swiper to use modules
    modules: [Navigation, Pagination, Autoplay],
    loop: true,
    autoplay: true,
  // If we need pagination
  pagination: {
    el: '.swiper-pagination',
  },

  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  });
